import { motion } from "framer-motion";
import BgImg from "../img/home/grille-b.jpg";
import Image1 from "../img/portfolio/casecobra.png";
import Image2 from "../img/portfolio/arkam-img.png";
import Image3 from "../img/portfolio/Insta-clone.png";
import Image4 from "../img/portfolio/flight.png";
import Image5 from "../img/portfolio/Vignette web I Hoobank.jpg";
import { transition1 } from "transitions";
import { Link } from "react-router-dom";
import AnimatedTitle from "components/AnimatedTitle";
import useScrollToTop from "hooks/useScrollToTop";

const projects = [
  {
    id: 1,
    title: "CASECOBRA",
    img: Image1,
    description:
      "CaseCobra est un site E-commerce avec un design apple qui permet d'acheter une coque d'Iphone personnaliser",
    url: "https://casecobra-sooty-five.vercel.app/",
  },
  {
    id: 2,
    title: "ARKAM",
    img: Image2,
    description:
      "Arkam est une association musicale qui organise des évènements sur la métropole Lilloise.",
    url: "https://arkam-experience.fr/",
  },
  {
    id: 3,
    title: "INSTAGRAM CLONE",
    img: Image3,
    description:
      "Ceci un clone du reseau social Instagram, j'ai essayé d'être le plus fidèle possible à ce dernier.",
    url: "https://instagram-clone-black-mu.vercel.app/",
  },
  {
    id: 4,
    title: "FLIGHT",
    img: Image4,
    description:
      "Flight propose la partie vitrine d'un site de réservation de vol.",
    url: "https://flight-lucasjules.fr/",
  },
  {
    id: 5,
    title: "HOOBANK",
    img: Image5,
    description:
      "Voici la partie vitrine d'un site de banque nouvelle génération.",
  },
];

const Portfolio = () => {
  useScrollToTop();
  
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        backgroundImage: `url(${BgImg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="bg-[#e1e1e1] flex min-h-screen items-center justify-center pt-[100px] lg:pt-[140px]"
      id="work"
    >
      <div className="lg:container mx-auto px-[1rem] lg:my-[3%]">
        <div className="flex flex-col lg:gap-x-10">
          <motion.div
            initial={{ opacity: 0, y: "-200%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-200%" }}
            transition={transition1}
            className="flex-1 flex flex-col lg:flex-row gap-10 mb-[24px] lg:mb-10"
          >
            <div className="flex-1 min-h-[300px] lg:h-[35vh] flex flex-col justify-center items-center lg:items-start border-2 border-transparent lg:justify-between">
              <div className="flex justify-center lg:block">
                <AnimatedTitle
                  title={["MY LATEST "]}
                  className="lg:tracking-wide lg:text-left text-[2rem] lg:text-[3vw]"
                />

                <AnimatedTitle
                  title={["WORK."]}
                  className="lg:tracking-wide lg:text-left text-[2rem] lg:text-[3vw] text-[#FF8811]"
                />
              </div>

              <p className="text-center lg:text-start max-w-xs sm:max-w-max mb-8 ">
                <span className="font-bold">
                  Bienvenue dans mon univers créatif !
                </span>
                <br />
                Chaque projet ici reflète ma passion pour le design et
                l'innovation. Découvrez des interfaces intuitives, des designs
                modernes, et des solutions créatives, conçus sur mesure pour
                répondre aux besoins variés de mes clients. Explorez mon travail
                et laissez-vous inspirer !
              </p>

              <Link
                to={"https://github.com/Mckennn"}
                target="_blank"
                className="btn w-full lg:w-[262px]"
              >
                <span>View all projects</span>
              </Link>
            </div>

            <div className="flex-1 pb-[4rem] xs:flex xs:flex-row-reverse xs:gap-8 xs:justify-center xs:items-center lg:pb-0 lg:block">
              <div className="flex lg:hidden pb-[1.75rem] max-h-[15rem] xs:flex-1">
                <div className="pr-3 flex flex-col justify-between">
                  <div className="block xs:hidden w-[1.25rem] h-[0.5rem] bg-black border-box mt-[0.5rem]"></div>
                  <div className="w-[2px] h-full bg-black border-box border-l-black border-[2px]"></div>
                  <div className="w-[0.5rem] h-[0.5rem] bg-black border-box"></div>
                </div>
                <div className="flex flex-col justify-between">
                  <div className="pb-[0.75rem]">
                    <h2 className="h2 text-[1.45rem] tracking-wide m-0">
                      {projects[0].title}
                    </h2>
                  </div>
                  <div className="pb-[2rem]">
                    <p>{projects[0].description}</p>
                  </div>
                  <div>
                    <a
                      href={projects[0].url}
                      target="_blank"
                      className="underline font-extrabold font-primary tracking-widest"
                    >
                      View website
                    </a>
                  </div>
                </div>
              </div>
              <a
                href={projects[0].url}
                target="_blank"
                className="xs:flex-1 lg:flex-0"
                key={projects[0].id}
              >
                <div className="group relative overflow-hidden border-2 border-black/20 rounded-xl h-[300px] sm:h-[350px] lg:h-[35vh] lg:min-h-[300px]">
                  <div className="hidden lg:block group-hover:bg-[black]/70 w-full h-full absolute z-40 transition-all duration-300"></div>

                  <img
                    src={projects[0].img}
                    alt={projects[0].title}
                    className="w-full h-full object-cover lg:group-hover:scale-125 transition-all duration-500"
                  />

                  <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-20 sm:group-hover:bottom-24 transition-all duration-500 z-50 text-white">
                    <span className="text-gradient">
                      {projects[0].description}
                    </span>
                  </div>

                  <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-12 sm:group-hover:bottom-14 transition-all duration-700 z-50 text-white">
                    <span className="text-2xl sm:text-3xl text-white">
                      {projects[0].title}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </motion.div>

          {/* Deuxième colonne - Les trois autres projets */}

          <motion.div
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "100%" }}
            transition={transition1}
            className="flex-1 flex flex-col gap-y-6 mb-[24px] lg:mb-0 lg:gap-y-10 lg:justify-between"
          >
            <div className="hidden lg:grid grid-cols-2 gap-10">
              {projects.slice(1).map((project) => (
                <div
                  key={project.id}
                  className="pb-[4rem] xs:flex xs:flex-row-reverse xs:gap-8 xs:justify-center xs;items-center lg:pb-0 lg:block"
                >
                  <div className="flex lg:hidden pb-[1.75rem] max-h-[15rem] xs:flex-1">
                    <div className="pr-3 flex flex-col justify-between">
                      <div className="block xs:hidden w-[1.25rem] h-[0.5rem] bg-black border-box mt-[0.5rem]"></div>
                      <div className="w-[2px] h-full bg-black border-box border-l-black border-[2px]"></div>
                      <div className="w-[0.5rem] h-[0.5rem] bg-black border-box"></div>
                    </div>
                    <div className="flex flex-col justify-between">
                      <div className="pb-[0.75rem]">
                        <h2 className="h2 text-[1.45rem] tracking-wide m-0">
                          {project.title}
                        </h2>
                      </div>
                      <div className="pb-[2rem]">
                        <p>{project.description}</p>
                      </div>
                      <div className="">
                        <a
                          href={project.url}
                          target="_blank"
                          className="underline font-extrabold font-primary tracking-widest"
                        >
                          View website
                        </a>
                      </div>
                    </div>
                  </div>
                  <a href={project.url} target="_blank" className="xs:flex-1">
                    <div
                      key={project.id}
                      className="group relative overflow-hidden border-2 border-black/20 rounded-xl h-[300px] sm:h-[350px] lg:h-[35vh] lg:min-h-[300px]"
                    >
                      <div className="hidden lg:block group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>

                      <img
                        src={project.img}
                        alt={project.title}
                        className="w-full h-full object-cover lg:group-hover:scale-125 transition-all duration-500"
                      />

                      <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-20 sm:group-hover:bottom-24 transition-all duration-500 z-50 text-white">
                        <span className="text-gradient">
                          {project.description}
                        </span>
                      </div>

                      <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-12 sm:group-hover:bottom-14 transition-all duration-700 z-50 text-white">
                        <span className="text-2xl sm:text-3xl text-white">
                          {project.title}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            {/* Pour les écrans de 480px à 1024px */}

            <div className="lg:hidden">
              {projects.slice(1).map((project, index) => (
                <div
                  key={project.id}
                  className={`pb-[4rem] xs:flex xs:gap-8 xs:justify-center xs:items-center lg:pb-0 lg:block ${
                    index % 2 === 0 ? "xs:flex-row" : "xs:flex-row-reverse"
                  }`}
                >
                  <div
                    className={`flex lg:hidden pb-[1.75rem] max-h-[15rem] xs:flex-1 ${
                      index % 2 === 0 ? "xs:flex-row-reverse" : "xs:flex-row"
                    }`}
                  >
                    <div className="pr-3 flex flex-col justify-between">
                      <div className="block xs:hidden w-[1.25rem] h-[0.5rem] bg-black border-box mt-[0.5rem]"></div>
                      <div className="w-[2px] h-full bg-black border-box border-l-black border-[2px]"></div>
                      <div className="w-[0.5rem] h-[0.5rem] bg-black border-box"></div>
                    </div>
                    <div className="flex flex-col justify-between">
                      <div className="pb-[0.75rem]">
                        <h2 className="h2 text-[1.45rem] tracking-wide m-0">
                          {project.title}
                        </h2>
                      </div>
                      <div className="pb-[2rem]">
                        <p>{project.description}</p>
                      </div>
                      <div className="">
                        <a
                          href={project.url}
                          target="_blank"
                          className="underline font-extrabold font-primary tracking-widest"
                        >
                          View website
                        </a>
                      </div>
                    </div>
                  </div>
                  <a href={project.url} target="_blank" className="xs:flex-1">
                    <div className="group relative overflow-hidden border-2 border-black/20 rounded-xl h-[300px] sm:h-[350px] lg:h-[35vh] lg:min-h-[300px]">
                      <div className="hidden lg:block group-hover:bg-[black]/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                      <img
                        src={project.img}
                        alt={project.title}
                        className="w-full h-full object-cover lg:group-hover:scale-125 transition-all duration-500"
                      />
                      <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-20 sm:group-hover:bottom-24 transition-all duration-500 z-50 text-white">
                        <span className="text-gradient">
                          {project.description}
                        </span>
                      </div>
                      <div className="hidden lg:block absolute -bottom-full left-8 sm:left-12 group-hover:bottom-12 sm:group-hover:bottom-14 transition-all duration-700 z-50 text-white">
                        <span className="text-2xl sm:text-3xl text-white">
                          {project.title}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
