import { motion } from "framer-motion";
import { transition1 } from "transitions";

const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="w-full h-auto px-4 bg-[#e1e1e1] flex flex-col justify-center font-primary"
    >
      <div className="flex justify-end lg:text-[2vw]">
        <p>©2024. All rights reserved.</p>
      </div>
    </motion.footer>
  );
};

export default Footer;
