import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import AnimatedTitle from "components/AnimatedTitle";
import Footer from "components/Footer";
import { Toaster, toast } from "sonner";

const info = [
  {
    icon: <FaPhoneAlt />,
    title: "Phone :",
    description: "06 31 89 45 66",
  },
  {
    icon: <FaEnvelope />,
    title: "Email :",
    description: "lucasju@hotmail.fr",
  },
  {
    icon: <FaMapMarkerAlt />,
    title: "Address :",
    description: "59000 Lille, France",
  },
];

const Contact = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .sendForm(
        "service_g2rmp9q",
        "template_2vct25s",
        form.current,
        "65cpTbJonagAtRqck"
      )
      .then(
        (result) => {
          console.log("message sent");
          toast.success(
            "Message envoyé avec succès ! Nous vous recontacterons dès que possible."
          );
          setMessageSent(true);
          setError(false);
          setIsLoading(false);
          e.target.reset();
        },
        (error) => {
          console.log("message not sent");
          toast.error("Une erreur s'est produite, veuillez réessayer.");
          setError(true);
          setMessageSent(false);
          setIsLoading(false);
        }
      );
  };

  return (
    <div className="">
      <Toaster position="top-center" richColors />
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="h-screen bg-[#e1e1e1] min-h-[700px] lg:pt-[140px] flex flex-col justify-between"
      >
        <div className="container mx-auto lg:h-[80vh]">
          <div className="flex flex-col lg:flex-row h-full items-center justify-start pt-[100px] lg:pt-0 gap-x-8 text-center lg:text-left">
            <div className="lg:flex-1 px-4">
              <div className="flex justify-center lg:justify-normal">
                <AnimatedTitle
                  title={["CONTACT "]}
                  className="lg:tracking-wide lg:text-left lg:text-[5vw]"
                />
                <AnimatedTitle
                  title={["ME."]}
                  className="lg:tracking-wide lg:text-left lg:text-[5vw] text-[#FF8811]"
                />
              </div>
              <p className="mb-4 lg:text-[1.5vw]">I would love to get suggestions from you.</p>

              <div className="flex-1 flex items-center order-1 xl:order-none mb-8 xl:mb-0">
                <ul className="flex flex-col lg:grid lg:grid-cols-2 gap-6">
                  {info.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`flex items-center gap-6 ${
                          item.title === "Address" ? "lg:col-span-2" : ""
                        }`}
                      >
                        <div className="w-[52px] h-[52px] bg-[#FF8811]/70 rounded-md flex items-center justify-center">
                          <div className="text-[28px]">{item.icon}</div>
                        </div>
                        <div className="flex flex-col items-start">
                          <p>{item.title}</p>
                          <h3>{item.description}</h3>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <form
                ref={form}
                onSubmit={sendEmail}
                className="flex flex-col gap-y-4 relative"
              >
                <div className="flex gap-x-10">
                  <input
                    type="text"
                    id="name"
                    name="user_name"
                    placeholder="Your name*"
                    required
                    className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  />
                  <input
                    type="email"
                    id="email"
                    name="user_email"
                    placeholder="Your email address*"
                    required
                    className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  />
                </div>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your message*"
                  required
                  className="pt-[15px] outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                />
                <button
                  type="submit"
                  className="btn min-w-[10vw] mb-[30px] mx-auto lg:mx-0 self-start"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    <span>Send it</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </motion.section>
    </div>
  );
};

export default Contact;
